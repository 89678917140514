import React, { useContext, useEffect, useState, Suspense } from 'react';
import { PContext } from '../context/PendingContext';
import { Utils } from '../context/UtilsContext';
import Layout from '../layout/Layout';
import PendingListTable from '../components/PendingList/PendingListTable';
import Filter from '../components/Filter/Filter';
import PrInformationModal from '../components/PrInformation/PrInformationModal';
import RemarksModal from '../components/Remarks/RemarksModal';

const PendingPrView = () => {
  const defaultFilter = {
    sort: null,
    search: '',
    status: 'PENDING',
  };

  const {
    values,
    getpendingApprovalList,
    getSpoDetails,
    approveOrReject,
    updateRemarks,
    downloadAttachment,
  } = useContext(PContext);
  const [modalData, setModalData] = useState({ visible: false, data: {} });
  const [remarksData, setRemarksData] = useState({ visible: false, data: {} });
  const { setLoading } = useContext(Utils);
  const [filter, setFilter] = useState(defaultFilter);
  const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 });

  // pagination & filter
  const resetPagination = () => setPaginate({ page: 1, pageSize: 10 });
  const onResetFilter = () => {
    getpendingApprovalList(setLoading, defaultFilter);
    setFilter(defaultFilter);
    resetPagination();
  };

  const onFilter = () => {
    getpendingApprovalList(setLoading, filter);
    resetPagination();
  };

  const shouldFetchFromServer = ({ page, pageSize }) => {
    const totalData = page * pageSize;
    const currentDataLength = values.poList.length; //
    const dataTotalLength = values.poListLength;
    let shouldReload = false;
    if (
      totalData > currentDataLength &&
      (dataTotalLength > totalData ||
        (dataTotalLength > totalData - pageSize &&
          dataTotalLength > currentDataLength))
    ) {
      shouldReload = true;
    }

    const shouldLoadAll =
      (totalData > dataTotalLength && dataTotalLength > totalData - pageSize) ||
      totalData === dataTotalLength
        ? `&start=${currentDataLength}&end=${dataTotalLength}`
        : ``;

    return {
      shouldReload,
      newPage: Math.floor(currentDataLength / 1000) + 1,
      shouldLoadAll,
    };
  };

  const onPageChange = (page, pageSize) => {
    const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
      page,
      pageSize,
    });
    if (shouldReload) {
      getpendingApprovalList(setLoading, filter, newPage, shouldLoadAll);
    }
    setPaginate({ page, pageSize });
  };

  const onSizeChange = (page, pageSize) => {
    const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
      page,
      pageSize,
    });
    if (shouldReload) {
      getpendingApprovalList(setLoading, filter, newPage, shouldLoadAll);
    }
    setPaginate({ page, pageSize });
  };

  const { poList } = values;
  const openModal = (data, type) => {
    if (type === 'pr') setModalData({ visible: true, data });
    if (type === 'remarks') setRemarksData({ visible: true, data });
  };
  const closeModal = type => {
    if (type === 'pr') setModalData({ visible: false, data: {} });
    if (type === 'remarks') setRemarksData({ visible: false, data: {} });
  };

  useEffect(() => getpendingApprovalList(setLoading, defaultFilter), []);
  return (
    <>
      <br />
      <h2>PENDING PO LIST</h2>
      <Filter
        onResetFilter={onResetFilter}
        onFilter={onFilter}
        filter={filter}
        setFilter={setFilter}
      />
      <PendingListTable
        poList={poList}
        openModal={openModal}
        approveOrReject={approveOrReject}
        updateRemarks={updateRemarks}
        setLoading={setLoading}
        paginate={paginate}
        onSizeChange={onSizeChange}
        onPageChange={onPageChange}
        recordLength={values.poListLength}
        downloadAttachment={downloadAttachment}
      />
      {modalData.visible && (
        <PrInformationModal
          visible={modalData.visible}
          data={modalData.data}
          closeModal={closeModal}
          getSpoDetails={getSpoDetails}
        />
      )}

      {remarksData.visible && (
        <RemarksModal
          visible={remarksData.visible}
          data={remarksData.data}
          closeModal={closeModal}
          updateRemarks={updateRemarks}
          setLoading={setLoading}
        />
      )}


        {/* <PendingListTable
 
          // poList={poList}
          // setLoading={setLoading}
     
          // openModal={openModal}
          downloadAttachment={downloadAttachment}
        /> */}
   

    </>
  );
};

export default Layout(PendingPrView);
